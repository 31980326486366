import { useEffect } from "react";

const useSetSessionData = (type, mainImage, text, croppedImages, size, color, largerImages) => {
    useEffect(() => {
        const croppedImagesSessionData = JSON.stringify({ croppedImages });
        sessionStorage.setItem('croppedImagesSessionData', croppedImagesSessionData)
        if (type === 'text') {
            const sessionData = JSON.stringify({ text, size, color, largerImages });
            sessionStorage.setItem('textCollageSessionData', sessionData);
        }
        else {
            const sessionData = JSON.stringify({ mainImage, size });
            sessionStorage.setItem('collageSessionData', sessionData);
        }
    }, [mainImage, text, croppedImages, size, color, largerImages]);
};

export default useSetSessionData;