import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CustomerRoutes from './routes/customerRoutes';
import AdminRoutes from './routes/adminRoutes';
import { ConstantsProvider } from './context/constantsContext';
import { ToastProvider } from './context/toastContext/toastContext';
import { AuthProvider } from './context/authContext';
import './App.css';
//TODO: Add 404 not found page right after CustomerRoutes

function App() {
  return (
    <ConstantsProvider>
      <ToastProvider>
        <Router>
          <Routes>
            <Route
              path="/admin/*"
              element={
                <AuthProvider>
                  <AdminRoutes />
                </AuthProvider>
              }
            />
            <Route path="/*" element={<CustomerRoutes />} />
          </Routes>
        </Router>
      </ToastProvider>
    </ConstantsProvider>
  );
}

export default App;
