import { useEffect } from 'react';
import { createFileFromBlobUrl } from '../../../../utils/modifyImage';

const useLoadSessionData = (
    type, 
    setText, 
    setCroppedImages, 
    setSize, 
    setColor, 
    setLargerImages, 
    mainImage, 
    setMainImage
) => {
    useEffect(() => {
        const loadDataFromSession = async () => {
            const croppedImagesSessionData = sessionStorage.getItem('croppedImagesSessionData');
            if (croppedImagesSessionData) {
                const parsedCroppedImages = JSON.parse(croppedImagesSessionData);
                const { croppedImages: storedCroppedImages } = parsedCroppedImages;
                if (storedCroppedImages.length > 0) {
                    try {
                        await createFileFromBlobUrl(storedCroppedImages[0], 'test1');
                        setCroppedImages(storedCroppedImages);
                    } catch {
                        setCroppedImages([]);
                    }
                }
            }
                  
            let sessionData = null;
            if (type === 'text') {
                sessionData = sessionStorage.getItem('textCollageSessionData');
            } else {
                sessionData = sessionStorage.getItem('collageSessionData');
            }

            if (sessionData) {
                const parsedData = JSON.parse(sessionData);
                if (type === 'text') {
                    const { 
                        text: storedText, 
                        size: storedSize, 
                        color: storedColor, 
                        largerImages: storedLargerImages 
                    } = parsedData;
                    if (storedText) setText(storedText);
                    if (storedSize) setSize(storedSize);
                    if (storedColor) setColor(storedColor);
                    if (storedLargerImages) setLargerImages(storedLargerImages);
                } else {
                    const { mainImage: storedMainImage, size: storedSize } = parsedData;
                    if (storedMainImage) {
                        try {
                            await createFileFromBlobUrl(storedMainImage, 'test2');
                            setMainImage(storedMainImage);
                        } catch {
                            setMainImage(null);
                        }
                    }
                    if (storedSize) setSize(storedSize);
                }
            }
        };

        loadDataFromSession();
    }, [sessionStorage, type, setText, setCroppedImages, setSize, setColor, setLargerImages, setMainImage]);
};

export default useLoadSessionData;