import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './billingPage.module.css';
import CheckoutForm from '../../../components/checkoutForm/checkoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import NavBar from '../../../layout/navBars/navBar';
import MediumLogoHeader from '../../../layout/mediumLogoHeader/mediumLogoHeader';
import { MARGINS, STRIPE_KEY } from '../../../utils/constants/constants';
import HeaderSection from '../../../components/headerSection';
import BillingSection from '../../../components/billingSection/billingSection';
import BillingRow from '../../../components/billingSection/billingRow';
import LoadingScreen from '../../../components/loadingScreen/loadingScreen';

function BillingPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState(null);
  const [baseCost, setBaseCost] = useState(null);
  const [shippingCost, setShippingCost] = useState(null);
  const [tax, setTax] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [tempImageId, setTempImageId] = useState(null);

  useEffect(() => {
    const {
      formData,
      baseCost,
      shippingCost,
      tax,
      quantity,
      tempImageId,
    } = location.state || {};

    if (!formData || !baseCost || !shippingCost || !tax || !quantity || !tempImageId) {
      navigate('/');
      return;
    }

    setFormData(formData);
    setBaseCost(baseCost);
    setShippingCost(shippingCost);
    setTax(tax);
    setQuantity(quantity);
    setTempImageId(tempImageId);
  }, [location.state, navigate]);

  const stripePromise = loadStripe(STRIPE_KEY);

  return (
    <div>
      <NavBar />
      {!(formData && baseCost && shippingCost && tax && quantity && tempImageId) ?
      <LoadingScreen />
      :
      <div className="App">
        <MediumLogoHeader title={"Checkout Page"}/>
        
        <HeaderSection 
          title="Shipping Info"
          fontSize={22}
          fontWeight='bold'
        >
          <div className={styles.orderInfo}>
            <p>{formData.firstname} {formData.lastname}</p>
            <p>{formData.address1}</p>
            {formData.address2 !== '' && <p>{formData.address2}</p>}
            <p>{formData.city}, {formData.state} {formData.zipCode}</p>
          </div>
        </HeaderSection>          

        <HeaderSection
          title="Pricing Info"
          fontSize={22}
          fontWeight='bold'
          marginTop={MARGINS.LARGE}
        >
          <BillingSection>
            <BillingRow 
              text1="Order Quantity"
              text2={quantity}
            />

            <BillingRow 
              text1="Order Cost"
              text2={`$${(baseCost * quantity / 100).toFixed(2)}`}
            />

            <BillingRow
              text1="Shipping Cost"
              text2={`+$${(shippingCost / 100).toFixed(2)}`}
            />

            <BillingRow
              text1="General Excise Tax"
              text2={`+$${(tax / 100).toFixed(2)}`}
              lastCharge={true}
            />

            <BillingRow
              text1="Total Cost"
              text2={`$${((baseCost * quantity + shippingCost + tax) / 100).toFixed(2)}`}
            />
          </BillingSection>
        </HeaderSection>
        
        <HeaderSection
          title="Billing Info"
          fontSize={22}
          fontWeight='bold'
          marginTop={MARGINS.LARGE}
        >
          <Elements stripe={stripePromise}>
            <CheckoutForm formValid={true} formData={formData} type={'order'} tempImageId={tempImageId} />
          </Elements>
        </HeaderSection>
      </div>
      }
    </div>
  );
}

export default BillingPage;